import axios from 'axios';
import { API_URL } from '../constants';
import { _dispatch, _axios, getSessionItem, setSessionItem, setMemoryItem } from '../utilities';
import { ToastsStore } from "react-toasts";
export const SAVE_DASHBOARD = 'SAVE_DASHBOARD';
export const CHANGE_INSTANT_CLASS_STATUS = 'CHANGE_INSTANT_CLASS_STATUS';
export const CHANGE_SCHEDULE_CLASS_STATUS = 'CHANGE_SCHEDULE_CLASS_STATUS';
export { _dispatch };

export function uploadDocument($this, payload) {
  const user = getSessionItem('user');

  return _axios({
    method: 'POST',
    data: payload,
    headers: { Authorization: `Bearer ${user.token}` },
    url: `${API_URL}/user/upload/attachments`,
  }, $this).catch((err) => {
    if (err.response.status === 401) {
      window.location = "/logout";
    }
    console.log(err);
  });
}
export function upgradePassword($this, oldpassword, newpassword) {
  // alert('called');
  const user = getSessionItem('user');

  return _axios({
    method: 'POST',
    //data: payload,
    headers: { Authorization: `Bearer ${user.token}` },
    data: {
      newPassword: newpassword, oldPassword: oldpassword
    },
    url: `${API_URL}/user/account/update-password`,
  }, $this).catch((err) => {
    if (err.response.status === 401) {
      window.location = "/logout";
    } else if(err.response.status === 403 || err.response.status === 400) {
      return err.response;
    }
    console.log(err);
  });

}
export function getReviews() {
  // alert('called');
  const user = getSessionItem('user');
  console.log(user);
  return axios({
    method: 'GET',
    //data: payload,
    // headers: { Authorization: `Bearer ${user.token}` },
    params: {
      id: user._id
    },
    url: `${API_URL}/user/teacher/profile/reviews`,
  }).catch((err) => {
    if (err.response.status === 401) {
      window.location = "/logout";
    }
    console.log(err);
  });

}
export function uploadVideos($this, payload,getVideoUploadPercentage) {
  const user = getSessionItem('user');
  return _axios({
    method: 'POST',
    data: payload,
    headers: { Authorization: `Bearer ${user.token}` },
    onUploadProgress: (progressEvent) => {
      const {loaded, total} = progressEvent;
      let percent = Math.floor((loaded * 100) / total)
      // console.log( `${loaded}kb of ${total}kb | ${percent}%` ) // just to see whats happening in the console
      if(percent <= 100) {
        if(getVideoUploadPercentage !== undefined)
          getVideoUploadPercentage(percent);
        // percent = percent;
      }
    },
    url: `${API_URL}/user/upload/videos`,
  }, $this).catch((err) => {
    if (err.response.status === 401) {
      window.location = "/logout";
    }
    console.log(err);
  });
}

export function saveProfile($this, values) {
  const user = getSessionItem('user');
  return _axios({
    method: 'PUT',
    data: values,
    headers: { Authorization: `Bearer ${user.token}` },
    url: `${API_URL}/user/profile/update/teacher`,
  }, $this).catch((err) => {
    if (err.response.status === 401) {
      window.location = "/logout";
    }
    console.log(err);
  });
}

export function updateTeacherProfile($this, values) {
  const user = getSessionItem('user');
  return _axios({
    method: 'PUT',
    data: values,
    headers: { Authorization: `Bearer ${user.token}` },
    url: `${API_URL}/user/teacher/update`,
  }, $this).catch((err) => {
    if (err.response.status === 401) {
      window.location = "/logout";
    } else if (err.response.status === 400) {
      return err.response;
    }
  });
};

export function fetchDocuments() {
  const user = getSessionItem('user');
  return axios({
    method: 'GET',
    headers: { Authorization: `Bearer ${user.token}` },
    url: `${API_URL}/user/request/expertise`,
  }).catch((err) => {
    if (err.response.status === 401) {
      window.location = "/logout";
    }
    console.log(err);
  });
}

export function teacherSignup($this, email, password) {
  return _axios({
    method: 'POST',
    data: { email, password },
    url: `${API_URL}/user/account/signup/teacher`,
  }, $this).catch((err) => {
    if (err.response.status === 401) {
      window.location = "/logout";
    }
    console.log(err);
  });
}

export function saveTeacherProfileStep1($this, values) {
  const user = getSessionItem('user');
  return _axios({
    method: 'PUT',
    data: values,
    headers: { Authorization: `Bearer ${user.token}` },
    url: `${API_URL}/user/teacher/registration-part-1`,
  }, $this).catch((err) => {
    if (err.response.status === 401) {
      window.location = "/logout";
    }
    console.log(err);
  });
}

export function saveTeacherProfileStep2($this, values) {
  const user = getSessionItem('user');
  return _axios({
    method: 'PUT',
    data: values,
    headers: { Authorization: `Bearer ${user.token}` },
    url: `${API_URL}/user/teacher/registration-part-2`,
  }, $this).catch((err) => {
    if (err.response.status === 401) {
      window.location = "/logout";
    }
    console.log(err);
  });
}

export function saveTeacherProfileStep3($this, values) {
  const user = getSessionItem('user');
  return _axios({
    method: 'PUT',
    data: values,
    headers: { Authorization: `Bearer ${user.token}` },
    url: `${API_URL}/user/teacher/registration-part-3`,
  }, $this).catch((err) => {
    if (err.response.status === 401) {
      window.location = "/logout";
    }
    console.log(err);
  });
}

export function saveTeacherProfileStep4($this, values) {
  const user = getSessionItem('user');
  return _axios({
    method: 'PUT',
    data: values,
    headers: { Authorization: `Bearer ${user.token}` },
    url: `${API_URL}/user/teacher/confirm-registration`,
  }, $this).catch((err) => {
    if (err.response.status === 401) {
      window.location = "/logout";
    }
    console.log(err);
  });
}

export function saveTeacherExpertise($this, values) {
  const user = getSessionItem('user');
  return _axios({
    method: 'PUT',
    data: values,
    headers: { Authorization: `Bearer ${user.token}` },
    url: `${API_URL}/user/teacher/update-expertise`,
  }, $this).catch((err) => {
    if (err.response.status === 401) {
      window.location = "/logout";
    }
    console.log(err);
  });
}


export function fetchTeacherInfo() {
  const user = getSessionItem('user');
  return axios({
    method: 'GET',
    headers: { Authorization: `Bearer ${user.token}` },
    url: `${API_URL}/user/teacher/account`,
  }).catch((err) => {
    if (err.response.status === 401) {
      window.location = "/logout";
    }
    console.log(err);
  });
}

export async function fetchTeacherDashboardData() {
  const user = getSessionItem('user');
  if(user) {
    let urlAPI;
    if (user.lastDashboardState === 'teacher') urlAPI = `${API_URL}/user/teacher/dashboard`;
    else urlAPI = `${API_URL}/user/student/dashboard`;
    return await axios({
      method: 'GET',
      headers: { Authorization: `Bearer ${user.token}` },
      url: urlAPI,
    }).catch((err) => {
      if (err.response.status === 401 || err.response.status === 500) {
        window.location = "/logout";
      }else if (err.response.status === 403 && err.response.data.error && err.response.data.error.notFound) {
        ToastsStore.error(err.response.data.error.notFound)
        setTimeout(()=>{
          window.location = "/logout";
        }, 4000);
      }
    });
  }else{
    return await axios({
      method: 'GET',
      url: `${API_URL}/user/student/dashboard-without-user`,
    }).catch((err) => {
      if (err.response && err.response.status === 401) {
        window.location = "/logout";
      }
      console.log(err);
    });
  }
  
}

export async function updatePopupStatus($this, data) {
  const user = getSessionItem('user');
  return axios({
    method: 'POST',
    data: data,
    headers: { Authorization: `Bearer ${user.token}` },
    url: `${API_URL}/user/update-popup-status`,
  }, $this).then(res => {
    user.showPopUpforCoupon = false;
    setSessionItem("user", user);
    setMemoryItem("user", user);
  }).catch((err) => {
    if (err.response.status === 401) {
      window.location = "/logout";
    }
    console.log(err);
  })
}

export function deleteInstantClass(data) {
  // alert(data);
  const user = getSessionItem('user');
  //alert(user.token);
  return axios({
    method: 'DELETE',
    params: { instantClassId: data },
    headers: { Authorization: `Bearer ${user.token}` },
    url: `${API_URL}/class/instant`,
  }).catch((err) => {
    if (err.response.status === 401) {
      window.location = "/logout";
    }
    console.log(err);
  })

}

// if you want delete both parent class and kids class then you send kidsClassId otherwise no need 
export function deleteScheduleClass(data, kidsClassId) {
  const user = getSessionItem('user');
  return axios({
    method: 'DELETE',
    params: { classId: data, kidsClassId },
    headers: { Authorization: `Bearer ${user.token}` },
    url: `${API_URL}/class/schedule/delete`,
  }).catch((err) => {
    if (err.response.status === 401) {
      window.location = "/logout";
    }
    console.log(err);
  })
}

export function saveDashboardData(data) {
  return {
    type: SAVE_DASHBOARD,
    payload: data
  };
}

export function createInfoUserPan($this, data) {
  const user = getSessionItem('user');
  return _axios({
    method: 'POST',
    data: data,
    headers: { Authorization: `Bearer ${user.token}` },
    url: `${API_URL}/user/teacher/pan-teacher`,
  }, $this).catch((err) => {
    if (err.response.status === 401) {
      window.location = "/logout";
    }
  });
}

export function updateInfoUserPan($this, data) {
  const user = getSessionItem('user');
  return _axios({
    method: 'PUT',
    data: data,
    headers: { Authorization: `Bearer ${user.token}` },
    url: `${API_URL}/user/teacher/update-info-pan-teacher`,
  }, $this).catch((err) => {
    if (err.response.status === 401) {
      window.location = "/logout";
    }
  });
}

export function createInstantClass($this, values, edit = false) {
  const user = getSessionItem('user');
  return _axios({
    method: edit === true ? 'PUT' : 'POST',
    data: values,
    headers: { Authorization: `Bearer ${user.token}` },
    url: `${API_URL}/class/instant`,
  }, $this).catch((err) => {
    if (err.response.status === 401) {
      window.location = "/logout";
    }
    console.log(err);
  });
}

export function getInstantClasses(params = {}) {
  const user = getSessionItem('user');
  return (dispatch) => {
    return axios({
      method: 'GET',
      params,
      headers: { Authorization: `Bearer ${user.token}` },
      url: `${API_URL}/class/instant`,
    }).then(response => {
      dispatch({
        type: SAVE_DASHBOARD,
        payload: { instantClassData: { instantClasses: response.data.body.instantClasses, hasNext: response.data.body.hasNext } }
      });
    }).catch((err) => {
      if (err.response.status === 401) {
        window.location = "/logout";
      }
      console.log(err);
    });
  };
}

export function changeClassStatus($this, action, activeClassData) {
  const user = getSessionItem('user');

  return (dispatch) => {
    return _axios({
      method: 'PUT',
      data: { action, instantClassId: activeClassData._id },
      headers: { Authorization: `Bearer ${user.token}` },
      url: `${API_URL}/class/instant/${action}`,
    }, $this).then(response => {
      dispatch({
        type: CHANGE_INSTANT_CLASS_STATUS,
        payload: { action, activeClassData }
      });
    }).catch((err) => {
      if (err.response.status === 401) {
        window.location = "/logout";
      }
      console.log(err);
      return (err)
    });
  };
}

export function changeScheduleClassStatus($this, isActive, activeClassData) {
  const user = getSessionItem('user');

  return (dispatch) => {
    return _axios({
      method: 'POST',
      data: { isActive, classId: activeClassData._id },
      headers: { Authorization: `Bearer ${user.token}` },
      url: `${API_URL}/class/schedule/handle-status`,
    }, $this).then(response => {
      dispatch({
        type: CHANGE_SCHEDULE_CLASS_STATUS,
        payload: { isActive, status: isActive ? 'active' : 'inactive', activeClassData }
      });
    });
  };
};

export async function changeScheduleClassStatusAsActive(isActive, activeClassData) {
  const user = getSessionItem('user');
  try {
    return await axios({
      method: 'POST',
      data: { isActive, classId: activeClassData._id },
      headers: { Authorization: `Bearer ${user.token}` },
      url: `${API_URL}/class/schedule/handle-status`,
    });
  } catch (err) {
    if (err.response.status === 401) {
      window.location = "/logout";
    }
  }
}

export function getBookings(params) {
  const user = getSessionItem('user');
  return axios({
    method: 'GET',
    headers: { Authorization: `Bearer ${user.token}` },
    params,
    url: `${API_URL}/bookings/teacher`,
  });
}

export function getTeacherProfile(id) {
  const user = getSessionItem('user');
  const data = {
    method: 'GET',
    params: { id },
    url: `${API_URL}/user/teacher/profile`,
  };
  if (user && user.token) {
    data.headers = { Authorization: `Bearer ${user.token}` };
  }
  return axios(data);
}

export function getTeacherReview(params) {
  return axios({
    method: 'GET',
    // headers: { Authorization: `Bearer ${user.token}` },
    params,
    url: `${API_URL}/user/teacher/profile/reviews`,
  });
}

export function getTeacherClasses(params) {
  const user = getSessionItem('user');
  const request = {
    method: 'GET',
    // headers: { Authorization: `Bearer ${user.token}` },
    params,
    url: `${API_URL}/user/teacher/profile/classes`,
  };
  if (user) {
    request.headers = { Authorization: `Bearer ${user.token}` };
  }
  return axios(request);
}

export function createScheduledClass(values, step, edit = false) {
  const user = getSessionItem('user');
  return axios({
    method: edit === true ? 'PUT' : 'POST',
    data: values,
    headers: { Authorization: `Bearer ${user.token}` },
    url: `${API_URL}/class/schedule/${step}`,
  }).catch((err) => {
    ToastsStore.error(err.response.data.message === "Bad Request" ? err.response.data.error.availabilities : err.response.data.message);
    if (err.response.status === 401) {
      window.location = "/logout";
    }
    console.log(err);
  });
}

export function getGroupClasses(params = {}) {
  const user = getSessionItem('user');
  return (dispatch) => {
    return axios({
      method: 'GET',
      params,
      headers: { Authorization: `Bearer ${user.token}` },
      url: `${API_URL}/class/group`,
    }).then(response => {
      dispatch({
        type: SAVE_DASHBOARD,
        payload: { instantClassData: { instantClasses: response.data.body.classData.classes, draftClasses: response.data.body.draftsData.classes, hasNext: response.data.body.classData.hasNext } }
      });
    }).catch((err) => {
      if (err.response.status === 401) {
        window.location = "/logout";
      }
      console.log(err);
    });
  };
}

export function getMasterClasses(params = {}) {
  const user = getSessionItem('user');
  return (dispatch) => {
    return axios({
      method: 'GET',
      params,
      headers: { Authorization: `Bearer ${user.token}` },
      url: `${API_URL}/class/master`,
    }).then(response => {
      dispatch({
        type: SAVE_DASHBOARD,
        payload: { instantClassData: { instantClasses: response.data.body.classData.classes, draftClasses: response.data.body.draftsData.classes, hasNext: response.data.body.classData.hasNext } }
      });
    }).catch((err) => {
      if (err.response.status === 401) {
        window.location = "/logout";
      }
      console.log(err);
    });
  };
}

export function getScheduledClasses(params = {}) {
  const user = getSessionItem('user');
  return (dispatch) => {
    return axios({
      method: 'GET',
      params,
      headers: { Authorization: `Bearer ${user.token}` },
      url: `${API_URL}/class/schedule`,
    }).then(response => {
      dispatch({
        type: SAVE_DASHBOARD,
        payload: { instantClassData: { instantClasses: response.data.body.classData.classes, draftClasses: response.data.body.draftsData.classes, hasNext: response.data.body.classData.hasNext } }
      });
    }).catch((err) => {
      if (err.response.status === 401) {
        window.location = "/logout";
      }
      console.log(err);
    });
  };
};

export function createClassAttachment($this, payload) {
  const user = getSessionItem('user');

  return _axios({
    method: 'POST',
    data: payload,
    headers: { Authorization: `Bearer ${user.token}` },
    url: `${API_URL}/class/attachment`,
  }, $this).catch((err) => {
    if (err.response.status === 401) {
      window.location = "/logout";
    }
    console.log(err);
  });
}

export async function saveTeacherProfileClass($this, values) {
  const user = getSessionItem('user');
  return await _axios({
    method: 'POST',
    data: values,
    headers: { Authorization: `Bearer ${user.token}` },
    url: `${API_URL}/class/draft/create`,
  }, $this).catch((err) => {
    if (err.response.status === 401) {
      window.location = "/logout";
    }
  });
}

export function saveTeacherProfile($this, values) {
  const user = getSessionItem('user');
  return _axios({
    method: 'PUT',
    data: values,
    headers: { Authorization: `Bearer ${user.token}`},
    url: `${API_URL}/user/teacher/update-teacher`,
  }, $this).catch((err) => {
    if (err.response.status === 401) {
      window.location = "/logout";
    }
  });
}

export function updateSlotDetailsForClasses($this, values) {
  const user = getSessionItem('user');
  return _axios({
    method: 'PUT',
    data: values,
    headers: { Authorization: `Bearer ${user.token}`},
    url: `${API_URL}/user/teacher/classes/update-slots`,
  }, $this).catch((err) => {
    if (err.response.status === 401) {
      window.location = "/logout";
    }
  });
}

export function updateClassAttachment($this, payload) {
  const user = getSessionItem('user');

  return _axios({
    method: 'PUT',
    data: payload,
    headers: { Authorization: `Bearer ${user.token}` },
    url: `${API_URL}/class/attachment/update`,
  }, $this).catch((err) => {
    if (err.response.status === 401) {
      window.location = "/logout";
    }
    console.log(err);
  });
}

export function deleteClassAttachment($this, payload) {
  const user = getSessionItem('user');

  return _axios({
    method: 'DELETE',
    data: payload,
    headers: { Authorization: `Bearer ${user.token}` },
    url: `${API_URL}/class/attachment/delete`,
  }, $this).catch((err) => {
    if (err.response.status === 401) {
      window.location = "/logout";
    }
    console.log(err);
  });
}

export function downloadAllClassAttachment($this, payload) {
  return _axios({
    method: 'POST',
    data: payload,
    url: `${API_URL}/class/attachment/download-all`,
    responseType: 'blob'
  }, $this).catch((err) => {
    if (err.response.status === 401) {
      window.location = "/logout";
    }
  });
}

export function getAllClassAttachments(params = {}) {
  const user = getSessionItem('user');
  const request ={
    method: 'GET',
    params,
    url: `${API_URL}/class/attachment/get-all`,
  };
  if (user) {
    request.headers = { Authorization: `Bearer ${user.token}` };
  }
  return axios(request).catch((err) => {
    if (err.response.status === 401) {
      window.location = "/logout";
    }
  });
};

export async function saveTeacherDocuments($this, values) {
  const user = getSessionItem('user');
  const request = {
    method: 'POST',
    data: values,
    url: `${API_URL}/user/account/teacher/create-documents`,
  };
  if (user) {
    request.headers = { Authorization: `Bearer ${user.token}` };
  }

  return axios(request, $this).catch((err) => {
    if (err.response.status === 401) {
      window.location = "/logout";
    }
    console.log(err);
  });
};

export async function deleteTeacherDocument($this,value) {
  const user = getSessionItem('user');
  const request = {
    method: 'DELETE',
    data: value,
    url: `${API_URL}/user/teacher/teacher-documents/delete`
  };
  if(user){
    request.headers = { Authorization: `Bearer ${user.token}` };
  }
  return axios(request, $this).catch((err) => {
    if (err.response.status === 401) {
      window.location = "/logout";
    }
    console.log(err);
  });
};

export async function updateTeacherDocuments($this, values) {
  const user = getSessionItem('user');
  const request = {
    method: 'PUT',
    data: values,
    url: `${API_URL}/user/account/teacher/update-documents`,
  };
  if (user) {
    request.headers = { Authorization: `Bearer ${user.token}` };
  }

  return axios(request, $this).catch((err) => {
    if (err.response.status === 401) {
      window.location = "/logout";
    }
    console.log(err);
  });
};

export async function updateTeacherOldDocuments($this, values) {
  const user = getSessionItem('user');
  const request = {
    method: 'PUT',
    data: values,
    url: `${API_URL}/user/account/teacher/update-old-documents`,
  };
  if (user) {
    request.headers = { Authorization: `Bearer ${user.token}` };
  }

  return axios(request, $this).catch((err) => {
    if (err.response.status === 401) {
      window.location = "/logout";
    }
    console.log(err);
  });
};