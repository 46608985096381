import axios from "axios";
import { store } from "../store";
import { API_URL } from "../constants";
import { _dispatch, getSessionItem } from "../utilities";
export { _dispatch };
export const MEETING_JOINED = "MEETING_JOINED";
export const MEETING_DONE = "MEETING_DONE";
export const UPDATE_MESSAGE_NOTIFICATION_STATUS =
  "UPDATE_MESSAGE_NOTIFICATION_STATUS";
export const SAVE_CLASS_DRAFT = "SAVE_CLASS_DRAFT";

export function fetchTimeZones() {
  return axios({
    method: "GET",
    url: `${API_URL}/auxiliary/timezones`,
  });
}

export function fetchLanguages() {
  return axios({
    method: "GET",
    url: `${API_URL}/auxiliary/languages`,
  }).catch((err) => {
    if (err.response.status === 401) {
      window.location = "/logout";
    }
    console.log(err);
  });
}

export function verifyPhoneSendOTP(data) {
  return axios({
    method: "POST",
    data,
    url: `${API_URL}/user/account/otp/phone`,
  });
}

export function fetchInstantClasses(
  skip,
  limit,
  deType,
  interestIds,
  topicId,
  rating,
  durationArray,
  priceLowerLimit,
  priceUpperLimit
) {
  const params = { skip, limit, deType };
  if (interestIds && interestIds.length) {
    params["interestIds"] = JSON.stringify(interestIds);
  }
  if (topicId) {
    params["topicId"] = topicId;
  }
  if (rating) {
    params["rating"] = rating;
  }
  if (durationArray && durationArray.length) {
    params["durationArray"] = JSON.stringify(
      durationArray.map((d) => parseInt(d))
    );
  }
  if (priceLowerLimit !== null && priceLowerLimit >= 0) {
    params["priceLowerLimit"] = priceLowerLimit;
  }
  if (priceUpperLimit !== null && priceUpperLimit >= 0) {
    params["priceUpperLimit"] = priceUpperLimit;
  }
  const request = {
    method: "GET",
    params,
    url: `${API_URL}/class/all/active-instant-classes`,
  };

  return axios(request).catch((err) => {
    if (err.response.status === 401) {
      window.location = "/logout";
    }
    console.log(err);
  });
}
export function fetchScheduleClasses(
  skip,
  limit,
  deType,
  interestIds,
  topicId,
  rating,
  durationArray,
  priceLowerLimit,
  priceUpperLimit,
  orderBy,
  order,
  selectedLevel
) {
  const user = getSessionItem("user");
  const params = { skip, limit, deType, orderBy, order };
  if (interestIds && interestIds.length) {
    params["interestIds"] = JSON.stringify(interestIds);
  }
  if (topicId) {
    params["topicId"] = topicId;
  }
  if (rating) {
    params["rating"] = rating;
  }
  if (durationArray && durationArray.length) {
    params["durationArray"] = JSON.stringify(
      durationArray.map((d) => parseInt(d))
    );
  }
  if (priceLowerLimit !== null && priceLowerLimit >= 0) {
    params["priceLowerLimit"] = priceLowerLimit;
  }
  if (priceUpperLimit !== null && priceUpperLimit >= 0) {
    params["priceUpperLimit"] = priceUpperLimit;
  }
  if (selectedLevel !== undefined && selectedLevel.length > 0) {
    params["level"] = JSON.stringify(selectedLevel);
  }

  const request = {
    method: "GET",
    params,
    url: `${API_URL}/class/all/schedule-classes`,
  };

  if (user) {
    request.headers = { Authorization: `Bearer ${user.token}` };
  }
  return axios(request).catch((err) => {
    if (err.response.status === 401) {
      window.location = "/logout";
    }
    console.log(err);
  });
}

export function fetchGroupClasses(
  skip,
  limit,
  deType,
  interestIds,
  topicId,
  rating,
  durationArray,
  priceLowerLimit,
  priceUpperLimit,
  orderBy,
  order,
  selectedLevel,
  languageIds
) {
  const user = getSessionItem("user");
  const params = { skip, limit, deType, orderBy, order };
  if (interestIds && interestIds.length) {
    params["interestIds"] = JSON.stringify(interestIds);
  }
  if (topicId) {
    params["topicId"] = topicId;
  }
  if (rating) {
    params["rating"] = rating;
  }
  if (durationArray && durationArray.length) {
    params["durationArray"] = JSON.stringify(
      durationArray.map((d) => parseInt(d))
    );
  }
  if (priceLowerLimit !== null && priceLowerLimit >= 0) {
    params["priceLowerLimit"] = priceLowerLimit;
  }
  if (priceUpperLimit !== null && priceUpperLimit >= 0) {
    params["priceUpperLimit"] = priceUpperLimit;
  }
  if (selectedLevel !== undefined && selectedLevel.length > 0) {
    params["level"] = JSON.stringify(selectedLevel);
  }
  if (languageIds && languageIds.length) {
    params["languageIds"] = JSON.stringify(languageIds);
  }
  const request = {
    method: "GET",
    params,
    url: `${API_URL}/class/all/group-classes`,
  };

  if (user) {
    request.headers = { Authorization: `Bearer ${user.token}` };
  }
  return axios(request).catch((err) => {
    if (err.response.status === 401) {
      window.location = "/logout";
    }
    console.log(err);
  });
}

export function fetchAllClasses(
  skip,
  limit,
  deType,
  interestIds,
  topicId,
  rating,
  durationArray,
  priceLowerLimit,
  priceUpperLimit,
  orderBy,
  order,
  selectedLevel,
  languageIds
) {
  const user = getSessionItem("user");
  const params = { skip, limit, deType, orderBy, order };
  if (interestIds && interestIds.length) {
    params["interestIds"] = JSON.stringify(interestIds);
  }
  if (topicId) {
    params["topicId"] = topicId;
  }
  if (rating) {
    params["rating"] = rating;
  }
  if (durationArray && durationArray.length) {
    params["durationArray"] = JSON.stringify(
      durationArray.map((d) => parseInt(d))
    );
  }
  if (priceLowerLimit !== null && priceLowerLimit >= 0) {
    params["priceLowerLimit"] = priceLowerLimit;
  }
  if (priceUpperLimit !== null && priceUpperLimit >= 0) {
    params["priceUpperLimit"] = priceUpperLimit;
  }
  if (selectedLevel !== undefined && selectedLevel.length > 0) {
    params["level"] = JSON.stringify(selectedLevel);
  }
  if (languageIds && languageIds.length) {
    params["languageIds"] = JSON.stringify(languageIds);
  }
  const request = {
    method: "GET",
    params,
    url: `${API_URL}/classes/all`,
  };

  if (user) {
    request.headers = { Authorization: `Bearer ${user.token}` };
  }
  return axios(request).catch((err) => {
    if (err.response.status === 401) {
      window.location = "/logout";
    }
    console.log(err);
  });
}

export function fetchMasterClasses(
  skip,
  limit,
  deType,
  interestIds,
  topicId,
  rating,
  durationArray,
  priceLowerLimit,
  priceUpperLimit,
  orderBy,
  order,
  selectedLevel
) {
  const user = getSessionItem("user");
  const params = { skip, limit, deType, orderBy, order };
  if (interestIds && interestIds.length) {
    params["interestIds"] = JSON.stringify(interestIds);
  }
  if (topicId) {
    params["topicId"] = topicId;
  }
  if (rating) {
    params["rating"] = rating;
  }
  if (durationArray && durationArray.length) {
    params["durationArray"] = JSON.stringify(
      durationArray.map((d) => parseInt(d))
    );
  }
  if (priceLowerLimit !== null && priceLowerLimit >= 0) {
    params["priceLowerLimit"] = priceLowerLimit;
  }
  if (priceUpperLimit !== null && priceUpperLimit >= 0) {
    params["priceUpperLimit"] = priceUpperLimit;
  }
  if (selectedLevel !== undefined && selectedLevel.length > 0) {
    params["level"] = JSON.stringify(selectedLevel);
  }
  const request = {
    method: "GET",
    params,
    url: `${API_URL}/class/all/master-classes`,
  };

  if (user) {
    request.headers = { Authorization: `Bearer ${user.token}` };
  }
  return axios(request).catch((err) => {
    if (err.response.status === 401) {
      window.location = "/logout";
    }
  });
}

export function getScheduleClassDetail(id) {
  const user = getSessionItem("user");

  const request = {
    method: "GET",
    params: { id },
    url: `${API_URL}/class/schedule/detail`,
  };

  if (user) {
    request.headers = { Authorization: `Bearer ${user.token}` };
  }

  return axios(request).catch((err) => {
    if (err.response.status === 401) {
      window.location = "/logout";
    }
    console.log(err);
  });
}

export function getUrlBasedClassDetail(url) {
  const user = getSessionItem("user");

  const request = {
    method: "GET",
    params: { url },
    url: `${API_URL}/class/detail/url`,
  };

  if (user) {
    request.headers = { Authorization: `Bearer ${user.token}` };
  }

  return axios(request).catch((err) => {
    if (err.response.status === 401) {
      window.location = "/logout";
    }
    console.log(err);
  });
}

export function switchUserProfile(role) {
  const user = getSessionItem("user");

  const request = {
    method: "POST",
    //params: {id},
    data: { role },
    url: `${API_URL}/user/switch-account`,
  };

  if (user) {
    // alert('indise if');
    console.log(user.token);
    request.headers = { Authorization: `Bearer ${user.token}` };
  }

  return axios(request).catch((err) => {
    if (err.response.status === 401) {
      window.location = "/logout";
    }
    console.log(err);
  });
}
export function favouriteClassCategories() {
  const user = getSessionItem("user");
  const request = {
    method: "GET",
    //data: {scheduleClassId,isFavourite},
    url: `${API_URL}/class/favourite`,
  };

  if (user) {
    request.headers = { Authorization: `Bearer ${user.token}` };
  }

  return axios(request).catch((err) => {
    if (err.response.status === 401) {
      window.location = "/logout";
    }
    console.log(err);
  });
}
export function favouriteClasses(categoryId) {
  //alert(categoryId);
  const user = getSessionItem("user");
  const request = {
    method: "GET",
    params: { categoryId },
    url: `${API_URL}/class/favourite/category`,
  };

  if (user) {
    request.headers = { Authorization: `Bearer ${user.token}` };
  }

  return axios(request).catch((err) => {
    if (err.response.status === 401) {
      window.location = "/logout";
    }
    console.log(err);
  });
}
export function favouriteScheduleClass(scheduleClassId, Favourite) {
  const user = getSessionItem("user");
  const isFavourite = !Favourite;
  const request = {
    method: "POST",
    data: { scheduleClassId, isFavourite },
    url: `${API_URL}/class/favourite`,
  };

  if (user) {
    request.headers = { Authorization: `Bearer ${user.token}` };
  }

  return axios(request).catch((err) => {
    if (err.response.status === 401) {
      window.location = "/logout";
    }
    console.log(err);
  });
}
export function fetchFollowings(type) {
  const user = getSessionItem("user");

  const request = {
    method: "GET",
    params: { type },
    url: `${API_URL}/user/follows`,
  };

  if (user) {
    request.headers = { Authorization: `Bearer ${user.token}` };
  }

  return axios(request).catch((err) => {
    if (err.response.status === 401) {
      window.location = "/logout";
    }
    console.log(err);
  });
}
export function fetchFollowers() {
  const user = getSessionItem("user");

  const request = {
    method: "GET",
    url: `${API_URL}/user/teacher/follows`,
  };

  if (user) {
    request.headers = { Authorization: `Bearer ${user.token}` };
  }

  return axios(request).catch((err) => {
    if (err.response.status === 401) {
      window.location = "/logout";
    }
    console.log(err);
  });
}
export function feedback(fields) {
  console.log(fields);
  const user = getSessionItem("user");

  const request = {
    method: "POST",
    data: fields,
    url: `${API_URL}/user/feedback`,
  };

  if (user) {
    request.headers = { Authorization: `Bearer ${user.token}` };
  }

  return axios(request).catch((err) => {
    if (err.response.status === 401) {
      window.location = "/logout";
    }
    console.log(err);
  });
}

export function publicFeedback(fields) {
  const request = {
    method: "POST",
    data: fields,
    url: `${API_URL}/user/public-feedback`,
  };

  return axios(request).catch((err) => {
    if (err.response.status === 401) {
      window.location = "/logout";
    }
    console.log(err);
  });
}

export function fetchCategotyIds() {
  const user = getSessionItem("user");

  const request = {
    method: "GET",
    url: `${API_URL}/auxiliary/feedback/categories`,
  };

  if (user) {
    request.headers = { Authorization: `Bearer ${user.token}` };
  }

  return axios(request).catch((err) => {
    if (err.response.status === 401) {
      window.location = "/logout";
    }
    console.log(err);
  });
}
export function getInstantClassDetail(instantClassId) {
  const user = getSessionItem("user");

  const request = {
    method: "GET",
    params: { instantClassId },
    url: `${API_URL}/class/instant/detail`,
  };

  if (user) {
    request.headers = { Authorization: `Bearer ${user.token}` };
  }

  return axios(request).catch((err) => {
    if (err.response.status === 401) {
      window.location = "/logout";
    }
    console.log(err);
  });
}

export function getScheduledClassDetail(id, type) {
  const user = getSessionItem("user");

  const request = {
    method: "GET",
    params: { id },
    url: type === "group" ? `${API_URL}/class/group/detail` : `${API_URL}/class/schedule/detail` ,
  };

  if (user) {
    request.headers = { Authorization: `Bearer ${user.token}` };
  }

  // return (dispatch) => {
  return axios(request).catch((err) => {
    if (err.response.status === 401) {
      window.location = "/logout";
    }
    console.log(err);
  });
  // .then(response => {
  //     dispatch({
  //       type: SAVE_CLASS_DRAFT,
  //       payload: response.data.body
  //     });
  // });
  //  };
}

export function getGroupClassDetail(id) {
  const user = getSessionItem("user");

  const request = {
    method: "GET",
    params: { id },
    url: `${API_URL}/class/group/detail`,
  };

  if (user) {
    request.headers = { Authorization: `Bearer ${user.token}` };
  }

  return axios(request).catch((err) => {
    if (err.response.status === 401) {
      window.location = "/logout";
    }
    console.log(err);
  });

}

export function getMasterClassDetail(id) {
  const user = getSessionItem("user");

  const request = {
    method: "GET",
    params: { id },
    url: `${API_URL}/class/master/detail`,
  };

  if (user) {
    request.headers = { Authorization: `Bearer ${user.token}` };
  }

  return axios(request).catch((err) => {
    if (err.response.status === 401) {
      window.location = "/logout";
    }
    console.log(err);
  });

}

export function leftMeeting(booking_id, auth) {
  const socket = window.socket;
  socket.emit("user_left", { id: booking_id }, (data) => {
    console.log("USER LEFT THE MEETING", data);
  });
}

export function joinMeeting(booking_id, auth) {
  const socket = window.socket;
  // On join meeting connection
  socket.emit("join_meeting", { id: booking_id }, (data) => {
    console.log("JOIN MEETING", data);
    const { dispatch } = store;
    dispatch({
      type: MEETING_JOINED,
      payload: data,
    });
  });

  if (auth.lastDashboardState === "teacher") {
    // Listen teacher's recorder is ready to go live
    socket.on("recorder_ready", (message) => {
      console.log("TEACHER RECORDER READY", message);
      const { dispatch } = store;
      dispatch({
        type: MEETING_JOINED,
        payload: message,
      });
    });
  } else {
    if (auth.lastDashboardState === "student") {
      // Listen if teacher joined the meeting
      socket.on("teacher_joined", (message) => {
        console.log("TEACHER HAS JOINED", message);
        const { dispatch } = store;
        dispatch({
          type: MEETING_JOINED,
          payload: message,
        });
      });
      // Rate the class on completion
      socket.on("rate_class", (message) => {
        console.log("CLASS COMPLETED, NOW RATE IT", message);
        const { dispatch } = store;
        dispatch({
          type: MEETING_DONE,
        });
      });
    }
  }
}

export function sendNewChatRequest(params) {
  //alert('inside request');
  console.log(window.socket);
  const socket = window.socket;

  // On send the new chat request
  return new Promise((resolve, reject) => {
    console.log("params", params);
    socket.emit("chat_new_request", params, (data) => {
      //alert("inside emit");
      console.log("NEW CHAT REQUESTED", data);
      const { dispatch } = store;
      dispatch({
        type: "ADD_MESSAGE",
        conversationId: data.conversationId,
        payload: data,
      });
      resolve(data);
    });
  });
}

export function handleNewChatRequest(params) {
  const socket = window.socket;

  // handle new chat request Accept/Reject
  socket.emit("chat_handle_request", params, (data) => {
    console.log("HANDLE NEW CHAT REQUESTED", data);
    const { dispatch } = store;
    dispatch({
      type: "REMOVE_CONVERSATION",
      currentTab: "requests",
      conversationId: data.conversationId,
    });
  });
}

export async function joinConversation(params) {
  const socket = window.socket;

  // New chat message listener
  return await // On join meeting connection
  socket.emit("chat_join_conversation", params, (data) => {
    console.log("JOIN CONVERSATION", data);
  });
}

export async function sendNewChatMessage(params) {
  const socket = window.socket;

  // New chat message listener
  // return await // On join meeting connection
  // socket.emit("chat_new_message", params, (data) => {
  //   console.log("EMIT NEW CHAT MESSAGE", data);
  //   const { dispatch } = store;
  //   dispatch({
  //     type: "NEW_MESSAGE",
  //     payload: data,
  //   });
  // });

  return new Promise((resolve, reject) => {
    socket.emit("chat_new_message", params, (data) => {
      console.log("EMIT NEW CHAT MESSAGE", data);
      resolve(data);
    });
  });
}

export function newChatMessageSocket() {
  const socket = window.socket;

  // New chat message listener
  if(socket !== null){
    socket.on("chat_new_message", (data) => {
      console.log("LISTEN NEW CHAT MESSAGE", data);
      const { dispatch } = store;
      dispatch({
        type: "NEW_MESSAGE",
        payload: data,
      });
    });

    socket.on("chat_incoming_new_conversation", (message) => {
      console.log("New Chat Message", message);
    });
  }
}

export function checkNotificationAndMessagesStatus(params = {}) {
  const socket = window.socket;

  // New chat message listener
  socket.emit("notification_has_pending", params, (data) => {
    console.log("STATUS MESSAGE", data);
    const { dispatch } = store;
    dispatch({
      type: UPDATE_MESSAGE_NOTIFICATION_STATUS,
      payload: data,
    });
  });
}

export function markNotificationAsRead(params = {}) {
  const socket = window.socket;

  // Mark notification as read
  return new Promise((resolve, reject) => {
    socket.emit("notification_mark_read", params, (data) => {
      console.log("MARK NOTIFICATION AS READ", data);
      resolve(data);
    });
  });
};

export function getClassWebinarVideoByUrl(requetData) {
  const user = getSessionItem("user");

  const request = {
    method: "GET",
    params: requetData,
    url: `${API_URL}/class/webinar-details`,
  };

  if (user) {
    request.headers = { Authorization: `Bearer ${user.token}` };
  }

  return axios(request).catch((err) => {
    if (err.response.status === 401) {
      window.location = "/logout";
    }
    console.log(err);
  });
}
