import axios from "axios";
import { ToastsStore } from "react-toasts";
import { API_URL } from "../constants";
import { _dispatch, isAuth, setMemoryItem, setSessionItem } from "../utilities";
import { getSessionItem } from "../utilities";
export { _dispatch };
export const SAVE_HOME_LAYOUT = "SAVE_HOME_LAYOUT";
export const SAVE_PRODUCT_BLOCKS = "SAVE_PRODUCT_BLOCKS";
export const SAVE_WEB_HERO_SLIDER = "SAVE_WEB_HERO_SLIDER";
export const SAVE_MOBILE_HERO_SLIDER = "SAVE_MOBILE_HERO_SLIDER";
export const SAVE_TOP_RATED_PRODUCTS = "SAVE_TOP_RATED_PRODUCTS";
export const SAVE_AD_BLOCKS = "SAVE_AD_BLOCKS";

// export function fetchSections() {
//   return (dispatch) => {
//     return axios({
//         method: 'GET',
//         url: `${API_URL}/user/student/dashboard`,
//     }).then(response => {
//         dispatch({
//           type: SAVE_HOME_LAYOUT,
//           payload: response.data.body
//         });
//     });
//   };

// }
export function fetchSections() {
  return (dispatch) => {
    // alert('inside ferchsection');
    const user = getSessionItem("user");
    //	const isFavourite = !Favourite;
    //if(user)
    //alert(user.lastDashboardState);
    const request = {
      method: "GET",
      url: user ? `${API_URL}/user/student/dashboard` : `${API_URL}/user/student/dashboard-without-user`,
    };
    if (user) {
      request.headers = { Authorization: `Bearer ${user.token}` };
    }

    return axios(request)
      .then((response) => {
        //    alert("response.data.body");
        if(isAuth() && response.data.body && response.data.body.user){
          let auth = getSessionItem('user');
          if(!auth){
            auth = {};
          }
          const user = response.data.body.user;
          setSessionItem('user', {...auth, ...user});
          setMemoryItem('user', {...auth, ...user});
        }
        dispatch({
          type: SAVE_HOME_LAYOUT,
          payload: response.data.body,
        });
      })
      .catch((err) => {
        if (err.response.status === 401) {
          window.location = "/logout";
        }else if (err.response.status === 403 && err.response.data.error && err.response.data.error.notFound) {
          ToastsStore.error(err.response.data.error.notFound)
          setTimeout(()=>{
            window.location = "/logout";
          }, 4000);
        }
      });
  };
}
export function fetchSlider(sliderId) {
  return axios({
    method: "GET",
    url: `${API_URL}/slider/${sliderId}`,
  }).catch((err) => {
    if (err.response.status === 401) {
      window.location = "/logout";
    }
  });
}

export function getExchangeCurrency() {
  return axios({
    method: "GET",
    url: `${API_URL}/get-exchange-currency`,
  }).catch((err) => {
    if (err.response.status === 401) {
      window.location = "/logout";
    }
  });
}

export function getCurrencyList() {
  return axios({
    method: "GET",
    url: `${API_URL}/get-currency-list`,
  }).catch((err) => {
    if (err.response.status === 401) {
      window.location = "/logout";
    }
  });
}

export function updateUserCurrency(data) {

  const user = getSessionItem("user");

  const request = {
    method: "POST",
    url: `${API_URL}/update-user-currency`,
    data: data,
  };
  if (user) {
    request.headers = { Authorization: `Bearer ${user.token}` };
  }
  
  return axios(request).then((response) => {
    console.log(response);
  }).catch((err) => {
    if (err.response.status === 401) {
      window.location = "/logout";
    }
  });
}

export function fetchBanner(bannerId) {
  return axios({
    method: "GET",
    url: `${API_URL}/banner/${bannerId}`,
  }).catch((err) => {
    if (err.response.status === 401) {
      window.location = "/logout";
    }
  });
}

export function staticPagesList() {
  return axios({
    method: "GET",
    url: `${API_URL}/static-pages`,
  }).catch((err) => {
    if (err.response.status === 401) {
      window.location = "/logout";
    }
  });
}

export function loadStaticPage(slug) {
  return axios({
    method: "GET",
    url: `${API_URL}/page/content/${slug}`,
  }).catch((err) => {
    if (err.response.status === 401) {
      window.location = "/logout";
    }
  });
}

export function loadStores() {
  return axios({
    method: "GET",
    url: `${API_URL}/stores`,
  }).catch((err) => {
    if (err.response.status === 401) {
      window.location = "/logout";
    }
  });
}

export function storeProductBlocksInMemory(data, index) {
  return {
    type: SAVE_PRODUCT_BLOCKS,
    payload: data,
    index: index,
  };
}

export function storeAdsBlockInMemory(data, index) {
  return {
    type: SAVE_AD_BLOCKS,
    payload: data,
    index: index,
  };
}

export function storeWebHeroSliderInMemory(data) {
  return {
    type: SAVE_WEB_HERO_SLIDER,
    payload: data,
  };
}

export function storeMobileHeroSliderInMemory(data) {
  return {
    type: SAVE_MOBILE_HERO_SLIDER,
    payload: data,
  };
}

export function storeTopRatedProductsInMemory(data) {
  return {
    type: SAVE_TOP_RATED_PRODUCTS,
    payload: data,
  };
}

export function getAllBannerList() {
  return axios({
    method: "GET",
    url: `${API_URL}/banner-image/get-list`,
  }).catch((err) => {
    if (err.response.status === 401) {
      window.location = "/logout";
    }
  });
};

export function getDashboardDetails() {
  const user = getSessionItem('user');
  const requestData = {
    method: 'GET',
    url: user ? `${API_URL}/user/student/dashboard-course` :  `${API_URL}/user/student/dashboard-without-user-course`
  };
  if(user){
    requestData.headers = { Authorization: `Bearer ${user.token}` };
  }
  return axios(requestData).catch((err) => {
    if (err.response.status === 401) {
      window.location = "/logout";
    }
  });
};

export function getVisitorLocation() {
  return axios({
    method: "GET",
    url: `${API_URL}/user/trace`,
  }).then((response) => {
    const visitorLocation = getSessionItem('visitorLocation')
    if(response.data && response.data.body && (!visitorLocation || (visitorLocation.country !== response.data.body.country))){
      setMemoryItem('visitorLocation', response.data.body)
      setSessionItem('visitorLocation', response.data.body)
      if(response.data.body.country === 'IN') {
        setLocationData("IN");
      } else {
        setLocationData("GLOBAL");
      }
    }

    return response.data;
    
  }).catch((err) => {
    if (err.response.status === 401) {
      window.location = "/logout";
    }
  });
}

export const setLocationData = (selectedLocation) => {
  setMemoryItem('location', selectedLocation);
  setSessionItem('location', selectedLocation);
};
