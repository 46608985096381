import {
  SAVE_AUTH,
  _dispatch
} from '../actions/auth';

const INITIAL_STATE = { auth: false, status: null };

function authReducer(state = INITIAL_STATE, action) {
  switch(action.type) {
    case SAVE_AUTH:
      return _dispatch({ ...state, auth: action.payload }, true, 'auth');

    default:
      return state;
  }
}

export default authReducer;
