import {
  SAVE_HOME_LAYOUT,
  SAVE_PRODUCT_BLOCKS,
  SAVE_WEB_HERO_SLIDER,
  SAVE_MOBILE_HERO_SLIDER,
  SAVE_TOP_RATED_PRODUCTS,
  SAVE_AD_BLOCKS,
  _dispatch
} from '../actions/home';

const INITIAL_STATE = { components: {}, products: [], adBlocks: [], webHeroSlider: null, mobileHeroSlider: null, topRatedProducts: null };

function homeReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case SAVE_HOME_LAYOUT:
      return _dispatch({ ...state, components: action.payload }, true, 'home_components');

    case SAVE_PRODUCT_BLOCKS:
      const { payload, index } = action;
      let products = [...state.products];
      products[index] = payload;
      return { ...state, products };

    case SAVE_AD_BLOCKS:
      let adBlocks = [...state.adBlocks];
      adBlocks[action.index] = action.payload;
      return { ...state, adBlocks };

    case SAVE_WEB_HERO_SLIDER:
      return { ...state, webHeroSlider: action.payload };

    case SAVE_MOBILE_HERO_SLIDER:
      return { ...state, mobileHeroSlider: action.payload };

    case SAVE_TOP_RATED_PRODUCTS:
      return { ...state, topRatedProducts: action.payload };

    default:
      return state;
  }
}

export default homeReducer;
