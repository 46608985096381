import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';
import { routerReducer } from 'react-router-redux';

import Auth from './auth';
import Classes from './classes';
import Meeting from './meeting';
import Messages from './messages';
import Home from './home';

const appReducer = combineReducers({
  form: formReducer,
  Auth,
  Classes,
  Home,
  Messages,
  Meeting,
  routing: routerReducer
});

const rootReducer = (state, action) => {
  return appReducer(state, action)
}

export default rootReducer;